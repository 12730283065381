import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const AddRentalUnit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [unitIdentity, setUnitIdentity] = useState('');
  const [rentRate, setRentRate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [unitNotes, setUnitNotes] = useState('');
  const [roomType, setRoomType] = useState('');
  const [roomTypeChoices, setRoomTypeChoices] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoomTypeChoices = async () => {
      try {
        const response = await axiosInstance.get('/landlord/room-type-choices/');
        setRoomTypeChoices(response.data);
      } catch (error) {
        console.error('Failed to fetch room type choices:', error);
        setError('Failed to load room type choices. Please try again.');
      }
    };

    fetchRoomTypeChoices();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const currentMonthlyRentRate = {
        rent_rate: parseFloat(rentRate),
        start_date: startDate,
      };

      const newUnit = {
        property_with_rental_unit: id,
        unit_identity: unitIdentity,
        unit_notes: unitNotes,
        current_monthly_rent_rate: currentMonthlyRentRate,
        room_type: roomType,
      };

      const response = await axiosInstance.post('/landlord/rental-units/', newUnit, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log('New rental unit created:', response.data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to create new rental unit:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while creating a new rental unit');
      }
    }
  };

  return (
    <div className="container mx-auto max-w-md mt-8 p-6 border border-gray-300 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Add Rental Unit</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="unitIdentity" className="block font-medium mb-2">
            Unit Identity
          </label>
          <input
            id="unitIdentity"
            type="text"
            value={unitIdentity}
            onChange={(e) => setUnitIdentity(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="rentRate" className="block font-medium mb-2">
            Rent Rate
          </label>
          <input
            id="rentRate"
            type="number"
            value={rentRate}
            onChange={(e) => setRentRate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="startDate" className="block font-medium mb-2">
            Set Rent Rate Start Date
          </label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="roomType" className="block font-medium mb-2">
            Room Type
          </label>
          <select
            id="roomType"
            value={roomType}
            onChange={(e) => setRoomType(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            required
          >
            <option value="">Select a room type</option>
            {Object.entries(roomTypeChoices).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="unitNotes" className="block font-medium mb-2">
            Unit Notes
          </label>
          <textarea
            id="unitNotes"
            placeholder='You can add additional details on the unit, like the meter number, for easy reference and management.'
            value={unitNotes}
            onChange={(e) => setUnitNotes(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            rows={4}
          />
        </div>
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded-md"
        >
          Add Rental Unit
        </button>
      </form>
    </div>
  );
};

export default AddRentalUnit;
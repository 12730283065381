import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../Spinner';

const FireManager = () => {
    const [manager, setManager] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { propertyId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchManagerDetails = async () => {
            try {
                const response = await axiosInstance.get(`landlord/property/${propertyId}/fire-manager/`);
                setManager(response.data);
            } catch (err) {
                setError('Failed to fetch manager details.');
            } finally {
                setLoading(false);
            }
        };

        fetchManagerDetails();
    }, [propertyId]);

    const handleFireManager = async () => {
        try {
            await axiosInstance.post(`landlord/property/${propertyId}/fire-manager/`);
            navigate(-1);
        } catch (err) {
            setError('Failed to fire the manager.');
        }
    };

    if (loading) return <div className="flex justify-center items-center h-screen"><Spinner /></div>;
    if (error) return <div className="text-red-500 text-center mt-4">{error}</div>;
    if (!manager) return <div className="text-gray-500 text-center mt-4">No manager found.</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-semibold mb-6">Manager Details</h1>
            <div className="bg-white shadow-lg rounded-lg p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-4">
                        <p className="text-gray-700"><strong>Name:</strong> {manager.manager.first_name} {manager.manager.last_name}</p>
                        <p className="text-gray-700"><strong>Phone:</strong> {manager.manager.phone_number}</p>
                        <p className="text-gray-700"><strong>National Id Number:</strong> {manager.manager.national_id_number}</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-700"><strong>Managing Property:</strong> {manager.property_managed.name}</p>
                        <p className="text-gray-700"><strong>Started Managing On:</strong> {new Date(manager.management_start_date).toLocaleDateString()}</p>
                    </div>
                </div>
                <button 
                    onClick={() => setIsModalOpen(true)}
                    className="w-full bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md mt-4 transition duration-300"
                >
                    Fire Manager
                </button>
            </div>
            
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
                        <p>Are you sure you want to fire {manager.manager.first_name} {manager.manager.last_name}? They will no longer have access to {manager.property_managed.name}.</p>
                        <div className="mt-6 flex justify-end">
                            <button 
                                onClick={() => setIsModalOpen(false)}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-md mr-2 transition duration-300"
                            >
                                Cancel
                            </button>
                            <button 
                                onClick={() => {
                                    handleFireManager();
                                    setIsModalOpen(false);
                                }}
                                className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300"
                            >
                                Yes, Fire Manager
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FireManager;

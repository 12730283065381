import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from "./Navbar";
import Signin from "./authentication/Signin";
import Register from "./authentication/Register";
import ResetPassword from './authentication/Resetpassword';
import SetNewPassword from './authentication/SetNewPassword';
import Home from './Home';
import Alllandlordproperties from './landlord/Alllandlordproperties';
import Expensesonproperty from './landlord/Expensesonproperty';
import Landlordhomepage from './landlord/Landlordhomepage';
import Managepropertymanagers from './landlord/Managepropertymanagers';
import ResourceGateway from './authentication/ResourceGateway';
import Profile from './authentication/Profile';
import AddProperty from './landlord/AddProperty';
import EditProperty from './landlord/EditProperty';
import RentalUnits from './landlord/RentalUnits';
import Managementhome from './propertymanager/Managementhome';
import RentCollection from './propertymanager/RentCollection';
import TenantDetails from './propertymanager/TenantDetails';
import UnitDetails from './propertymanager/UnitDetails';
import Addrentalunit from './landlord/Addrentalunit';
import AddTenant from './propertymanager/AddTenant';
import PaymentHistory from './propertymanager/PaymentHistory';
import RentalPropertyIncome from './landlord/RentalPropertyIncome';
import EmployManager from './landlord/EmployManager';
import FireManager from './landlord/FireManager';
import MonthlyPaymentsInstances from './landlord/MonthlyPaymentsInstances';
import CreateProperty from './propertymanager/CreateProperty';
import ManagerPasswordReset from './authentication/ManagerPasswordReset';
import PropertyExpenses from './propertymanager/PropertyExpenses';
import { SearchProvider } from './SearchContext';
import TermsofService from './TermsofService';
import Pricing from './Pricing';
import Blog from './Blog';
import Contacts from './Contacts';


const ProtectedRoute = ({ element }) => {
  const accessToken = localStorage.getItem('accessToken');
  const isLoggedIn = !!accessToken;

  return isLoggedIn ? element : <Navigate to="/login" replace />;
};

const App = () => {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';
  const isResourceGatewayRoute = location.pathname === '/resource-gateway';

  return (
    <SearchProvider>
      <div>
        {!isLoginRoute && !isResourceGatewayRoute && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-new-password/:uid/:token" element={<SetNewPassword />} />
          <Route path="/termsofservice" element={<TermsofService />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contacts" element={<Contacts />} />
          

          <Route path="/landlord/properties" element={<ProtectedRoute element={<Alllandlordproperties />} />} />
          <Route path="/landlord/expenses" element={<ProtectedRoute element={<Expensesonproperty />} />} />
          <Route path="/landlord/home" element={<ProtectedRoute element={<Landlordhomepage />} />} />
          <Route path="/landlord/managers" element={<ProtectedRoute element={<Managepropertymanagers />} />} />
          <Route path="/resource-gateway" element={<ProtectedRoute element={<ResourceGateway />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/add-property" element={<ProtectedRoute element={<AddProperty />} />} />
          <Route path="/editproperty/:id" element={<ProtectedRoute element={<EditProperty />} />} />
          <Route path="/property-manager/home" element={<ProtectedRoute element={<Managementhome />} />} />
          <Route path="/property/:id/units" element={<ProtectedRoute element={<RentalUnits />} />} />
          <Route path="/rentcollection/:id/tenant" element={<ProtectedRoute element={<RentCollection />} />} />
          <Route path="/add-rental-unit/:id" element={<ProtectedRoute element={<Addrentalunit />} />} />
          <Route path="/tenantDetails/:id/tenant" element={<ProtectedRoute element={<TenantDetails />} />} />
          <Route path="/addtenant/:unitId/rentalunit" element={<ProtectedRoute element={<AddTenant />} />} />
          <Route path="/unitDetails/:id/unit" element={<ProtectedRoute element={<UnitDetails />} />} />
          <Route path="/payment-history/:tenantId" element={<ProtectedRoute element={<PaymentHistory />} />} />
          <Route path="/property/:propertyId/income" element={<ProtectedRoute element={<RentalPropertyIncome />} />} />
          <Route path="/property/:propertyId/employ-manager" element={<ProtectedRoute element={<EmployManager />} />} />
          <Route path="/property/:propertyId/fire-manager" element={<ProtectedRoute element={<FireManager />} />} />
          <Route path="/property/:propertyId/payments/:month/:year" element={<ProtectedRoute element={<MonthlyPaymentsInstances />} />} />
          <Route path="/createproperty" element={<ProtectedRoute element={<CreateProperty />} />} />
          <Route path="/managerpasswordreset" element={<ProtectedRoute element={<ManagerPasswordReset />} />} />
          <Route path="/property/:propertyId/expenses" element={<ProtectedRoute element={<PropertyExpenses />} />} />
        </Routes>
      </div>
    </SearchProvider>
  );
};

export default App;
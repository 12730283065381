import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaBuilding, FaMapMarkerAlt, FaEye, FaEyeSlash } from 'react-icons/fa';
import MainUrl from '../MainUrl';

const CountryCodeSelect = ({ value, onChange }) => {
  const countryCodes = [
    { code: '+254', country: 'Kenya' },
    { code: '+1', country: 'USA' },
    { code: '+44', country: 'UK' },
    { code: '+91', country: 'India' },
    { code: '+27', country: 'South Africa' },
    { code: '+234', country: 'Nigeria' },
  ];

  return (
    <select
      value={value}
      onChange={onChange}
      className="px-2 py-2 border-none rounded-l focus:outline-none bg-gray-100"
    >
      {countryCodes.map((country) => (
        <option key={country.code} value={country.code}>
          {country.code} ({country.country})
        </option>
      ))}
    </select>
  );
};

const Register = () => {
  const [formData, setFormData] = useState({
    countryCode: '+254',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    propertyName: '',
    propertyLocation: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const validateForm = () => {
    let errors = {};
    const requiredFields = ['phoneNumber', 'password', 'confirmPassword', 'propertyName', 'propertyLocation'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field.replace(/([A-Z])/g, ' $1').trim()} is required`;
      }
    });

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    // Check if password contains at least one letter
    if (!/[a-zA-Z]/.test(formData.password)) {
      errors.password = 'Password must contain at least one letter';
    }

    // Check if phone number has a minimum of ten characters and a maximum of 15 characters
    if (formData.phoneNumber.length < 9 || formData.phoneNumber.length > 15) {
      errors.phoneNumber = 'Phone number must have a minimum of ten characters and a maximum of 15 characters';
    }

    if (!acceptTerms) {
      errors.terms = 'You must accept the Terms and Conditions';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setError('');
      setSuccess(false);

      try {
        const response = await MainUrl.post('/authentication/register/landlord/', {
          phone_number: `${formData.countryCode}${formData.phoneNumber}`,
          email: formData.email || null,
          password: formData.password,
          password2: formData.confirmPassword,
          property_name: formData.propertyName,
          property_location: formData.propertyLocation,
          total_units: 0,
        });

        if (response.status === 200 || response.status === 201) {
          setSuccess(true);
          setFormData({
            countryCode: '+254',
            phoneNumber: '',
            email: '',
            password: '',
            confirmPassword: '',
            propertyName: '',
            propertyLocation: '',
          });
          setAcceptTerms(false);
        } else {
          setFormErrors(response.data);
        }
      } catch (err) {
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
        <h1 className="text-2xl font-bold mb-6 text-center text-indigo-600">Landlord Registration</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
          <div className="relative">
            <label className="block text-gray-700 mb-1">Phone Number:</label>
            <div className="flex items-center border rounded focus-within:ring focus-within:border-indigo-300">
              <CountryCodeSelect
                value={formData.countryCode}
                onChange={(e) => handleChange({ target: { name: 'countryCode', value: e.target.value } })}
              />
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full px-3 py-2 border-none rounded-r focus:outline-none"
                required
              />
            </div>
            {formErrors.phoneNumber && <p className="text-red-500 text-sm mt-1">{formErrors.phoneNumber}</p>}
          </div>
          <FormField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            handleChange={handleChange}
            icon={<FaEnvelope />}
            error={formErrors.email}
            required={true}
          />
          <PasswordField
            label="Password"
            name="password"
            value={formData.password}
            handleChange={handleChange}
            error={formErrors.password}
            showPassword={showPassword}
            toggleVisibility={togglePasswordVisibility}
          />
          <PasswordField
            label="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            handleChange={handleChange}
            error={formErrors.confirmPassword}
            showPassword={showConfirmPassword}
            toggleVisibility={toggleConfirmPasswordVisibility}
          />
          <FormField
            label="Property Name"
            name="propertyName"
            value={formData.propertyName}
            handleChange={handleChange}
            icon={<FaBuilding />}
            placeholder="Register Your First Property"
            error={formErrors.propertyName}
          />
          <FormField
            label="Location"
            placeholder="eg Rwaka opposite Two Rivers Mall"
            name="propertyLocation"
            value={formData.propertyLocation}
            handleChange={handleChange}
            icon={<FaMapMarkerAlt />}
            error={formErrors.propertyLocation}
          />
          <TermsCheckbox
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
            error={formErrors.terms}
          />
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-2 px-4 rounded bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring focus:border-indigo-300 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        {success && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded shadow-md max-w-md w-full text-center">
              <h2 className="text-2xl font-bold mb-4 text-green-600">Landlord registered successfully!</h2>
              <button
                onClick={() => navigate('/login')}
                className="mt-4 py-2 px-4 rounded bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring focus:border-indigo-300"
              >
                Login to add units into your property
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const FormField = ({ label, type = 'text', name, value, handleChange, placeholder, icon, error, required = true }) => (
  <div className="relative">
    <label className="block text-gray-700 mb-1">{label}:</label>
    <div className="flex items-center border rounded focus-within:ring focus-within:border-indigo-300">
      <span className="px-3 text-gray-500">{icon}</span>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full px-3 py-2 border-none rounded focus:outline-none"
        required={required}
      />
    </div>
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

const PasswordField = ({ label, name, value, handleChange, error, showPassword, toggleVisibility }) => (
  <div className="relative">
    <label className="block text-gray-700 mb-1">{label}:</label>
    <div className="flex items-center border rounded focus-within:ring focus-within:border-indigo-300">
      <span className="px-3 text-gray-500"><FaLock /></span>
      <input
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={handleChange}
        className="w-full px-3 py-2 border-none rounded focus:outline-none"
        required
      />
      <button
        type="button"
        onClick={toggleVisibility}
        className="px-3 text-gray-500 focus:outline-none"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

const TermsCheckbox = ({ checked, onChange, error }) => (
  <div className="relative">
    <label className="flex items-center text-gray-700">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="mr-2"
      />
      I accept the <Link to="/termsofservice" className="text-indigo-600 hover:underline ml-1">Terms and Conditions</Link>
    </label>
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

export default Register;

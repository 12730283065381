import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../Spinner';

const MonthlyPaymentsInstances = () => {
  const { propertyId, month, year } = useParams();
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  useEffect(() => {
    const fetchMonthlyPayments = async () => {
      setError(null);
      setLoading(true);

      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          setError('Access token not found. Please log in.');
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get(`/landlord/properties/${propertyId}/payments/?month=${month}&year=${year}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setPayments(response.data);
      } catch (error) {
        console.error('Failed to fetch monthly payments:', error);
        if (error.response?.status === 401) {
          setError('Your session has expired. Please log in again.');
        } else {
          setError('An error occurred while fetching the monthly payments. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchMonthlyPayments();
  }, [propertyId, month, year]);

  const handleDeleteClick = (payment) => {
    setSelectedPayment(payment);
    setShowModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedPayment) return;

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        setError('Access token not found. Please log in.');
        return;
      }

      await axiosInstance.delete(`/landlord/properties/${propertyId}/payments/?month=${month}&year=${year}&paymentId=${selectedPayment.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setPayments(payments.filter(p => p.id !== selectedPayment.id));
      setShowModal(false);
      setSelectedPayment(null);
    } catch (error) {
      console.error('Failed to delete payment:', error);
      setError('An error occurred while deleting the payment. Please try again later.');
    }
  };

  const ConfirmationModal = ({ show, onClose, onConfirm, payment }) => {
    if (!show) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
        <div className="bg-white w-full max-w-md p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
          <p className="mb-4">
            Are you sure you want to delete the payment of tenant <strong>{payment.tenant_name}</strong>,
            amounting to <strong>KES {payment.amount_paid.toLocaleString()}</strong> for rental unit <strong>{payment.rental_unit_paid_for}</strong> paid on <strong>{payment.date_paid}</strong>?
          </p>
          <p className="mb-4">
            This action cannot be undone. Please proceed with caution.
          </p>
          <div className="flex justify-end space-x-4">
            <button onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Cancel
            </button>
            <button onClick={onConfirm} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 text-red-500 text-center">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Rent Payment Instances for {month}/{year}</h2>
      {payments.length === 0 ? (
        <p>No payment instances for this month.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tenant</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rental Unit</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Mode</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Paid</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {payments.map(payment => (
                <tr key={payment.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.tenant_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.rental_unit_paid_for}</td>
                  <td className="px-6 py-4 whitespace-nowrap">KES {payment.amount_paid.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.mode_of_payment}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.date_paid}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button onClick={() => handleDeleteClick(payment)} className="text-red-500 hover:text-red-700 font-medium">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-4">
        <Link to={`/property/${propertyId}/income`} className="text-blue-600 underline">
          Back to Financial Summary
        </Link>
      </div>
      <ConfirmationModal show={showModal} onClose={() => setShowModal(false)} onConfirm={handleDeleteConfirm} payment={selectedPayment} />
    </div>
  );
};

export default MonthlyPaymentsInstances;

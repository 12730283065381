import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from "react-router-dom";
import { FaChevronRight, FaEllipsisH, FaBuilding, FaMoneyBillAlt, FaUser } from "react-icons/fa";
import Spinner from '../Spinner';

const Managementhome = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedAmenities, setExpandedAmenities] = useState({});
    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await axiosInstance.get('management/managed-properties/');
                setProperties(response.data);
            } catch (error) {
                console.error("There was an error fetching the properties!", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperties();

        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 60000);

        return () => clearTimeout(timer);
    }, []);

    const toggleAmenities = (propertyId) => {
        setExpandedAmenities(prev => ({
            ...prev,
            [propertyId]: !prev[propertyId],
        }));
    };

    if (loading) return <Spinner />;

    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="container mx-auto">
                <h1 className="text-4xl font-bold text-gray-800 mb-8">Managed Properties</h1>
                {showMessage && <ImportantNotice />}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {properties.map(property => (
                        <PropertyCard
                            key={property.id}
                            property={property}
                            expanded={expandedAmenities[property.id]}
                            onToggleAmenities={() => toggleAmenities(property.id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const ImportantNotice = () => (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8 rounded-r-lg shadow">
        <p className="font-bold mb-2">Important Notice</p>
        <p>Dear Manager, if this is your first time being employed, your national ID currently serves as your password. Please change it immediately using this <Link to="/managerpasswordreset" className="text-indigo-600 underline font-semibold">link</Link> to prevent unauthorized access to your account. Subsequent employment will not affect your phone number or password.</p>
    </div>
);

const PropertyCard = ({ property, expanded, onToggleAmenities }) => {
    return (
        <div className="bg-white shadow-lg rounded-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
            <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    {property.name}
                </h2>
                <PropertyInfo icon={<FaBuilding />} label="Location" value={property.location} />
                <PropertyLink icon={<FaBuilding />} label="Total Rental Units" value={property.total_units} to={`/property/${property.id}/units`} />
                <PropertyLink icon={<FaMoneyBillAlt />} label="Expenses" to={`/property/${property.id}/expenses`} />
                <PropertyInfo icon={<FaUser />} label="Landlord" value={property.landlord_name} />
                <AmenitiesList 
                    amenities={property.amenities} 
                    expanded={expanded}
                    onToggle={onToggleAmenities}
                />
            </div>
        </div>
    );
};

const PropertyInfo = ({ icon, label, value }) => (
    <div className="flex items-center mb-2 text-gray-700">
        {React.cloneElement(icon, { className: "mr-2 text-indigo-600" })}
        <strong>{label}:</strong> <span className="ml-1">{value}</span>
    </div>
);

const PropertyLink = ({ icon, label, value, to }) => (
    <Link to={to} className="flex items-center mb-2 text-indigo-600 hover:text-indigo-800 group">
        {React.cloneElement(icon, { className: "mr-2" })}
        <strong>{label}:</strong> 
        {value && <span className="ml-1">{value}</span>}
        <FaChevronRight className="ml-auto transition-transform group-hover:translate-x-1" />
    </Link>
);

const AmenitiesList = ({ amenities, expanded, onToggle }) => (
    <div className="mt-4">
        <p className="text-gray-700 flex items-center font-semibold mb-2">
            Amenities:
            {amenities && amenities.length > 3 && (
                <button
                    className="ml-2 text-indigo-600 hover:text-indigo-800 focus:outline-none"
                    onClick={onToggle}
                >
                    <FaEllipsisH />
                </button>
            )}
        </p>
        <ul className="list-disc pl-6">
            {amenities && amenities.length > 0 ? (
                <>
                    {amenities.slice(0, 3).map((amenity, index) => (
                        <li key={index} className="text-gray-700">{amenity.name}</li>
                    ))}
                    {amenities.length > 3 && (
                        expanded ? (
                            <>
                                {amenities.slice(3).map((amenity, index) => (
                                    <li key={index + 3} className="text-gray-700">{amenity.name}</li>
                                ))}
                                <li>
                                    <button
                                        className="text-indigo-600 hover:underline focus:outline-none"
                                        onClick={onToggle}
                                    >
                                        Show Less
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li>
                                <button
                                    className="text-indigo-600 hover:underline focus:outline-none"
                                    onClick={onToggle}
                                >
                                    View More
                                </button>
                            </li>
                        )
                    )}
                </>
            ) : (
                <li className="text-gray-700">None</li>
            )}
        </ul>
    </div>
);

export default Managementhome;
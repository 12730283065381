import React, { useState, useEffect, useCallback } from "react";
import Spinner from "./Spinner";
import { useSearch } from "./SearchContext";
import MainUrl from "./MainUrl";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import {TiMessages} from "react-icons/ti"

const Home = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedAmenities, setExpandedAmenities] = useState({});
  const { searchTerm, setIsSearching } = useSearch();

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const url = searchTerm
        ? `/tenant/search/?q=${encodeURIComponent(searchTerm)}`
        : "/tenant/vacant-properties/";
      const response = await MainUrl.get(url);
      setProperties(response.data.results);
    } catch (err) {
      setError("Failed to fetch properties. Please try again later.");
      setProperties([]);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  }, [searchTerm, setIsSearching]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const toggleAmenities = (propertyId) => {
    setExpandedAmenities((prev) => ({
      ...prev,
      [propertyId]: !prev[propertyId],
    }));
  };

  const highlightSearchTerm = (text, searchTerm) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <span key={index} className="text-red-500">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  if (loading) return <Spinner />;
  if (error)
    return (
      <div className="text-red-500 text-center font-semibold">{error}</div>
    );

  return (
    <div className="bg-gray-100 min-h-screen">
      <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center w-full px-2 py-1 mb-2 text-green-700 bg-gradient-to-r from-green-100 to-green-200 shadow-md transition-all duration-300 hover:shadow-lg hover:scale-102">
        <span className="block">Find Vacant Rental Units</span>
        <span className="block text-sm sm:text-base md:text-lg text-green-600">
          in Real Time
        </span>
      </h2>
      <div className="container mx-auto px-4 pt-2">
        {properties.length === 0 ? (
          <div className="text-gray-500 text-center font-semibold text-xl">
            {searchTerm
              ? "No properties found matching your search."
              : "No properties available at the moment."}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {properties.map((property) => (
              <div
                key={property.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden transition duration-300 hover:shadow-xl"
              >
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2 text-green-700">
                    {highlightSearchTerm(property.name, searchTerm)}
                  </h2>
                  <p className="text-gray-600 mb-3 text-sm">
                    Location:{" "}
                    {highlightSearchTerm(property.location, searchTerm)}
                  </p>

                  <div className="bg-orange-50 rounded-lg p-3 mb-3 border-2 border-orange-200">
                    <h3 className="text-lg font-semibold mb-2 text-orange-700 border-b border-orange-200 pb-1">
                      Currently Available Rental Units:
                    </h3>
                    <div className="space-y-2 max-h-48 overflow-y-auto pr-2">
                      {property.vacant_units.map((unit) => (
                        <div
                          key={unit.id}
                          className="bg-orange-100 p-2 rounded-md border border-orange-300"
                        >
                          <p className="font-semibold text-orange-800">
                            {highlightSearchTerm(unit.room_type, searchTerm)}
                          </p>
                          <p className="text-gray-700 text-sm">
                            Rent:{" "}
                            <span className="text-orange-600 font-bold">
                              Ksh{" "}
                              {parseFloat(
                                unit.current_monthly_rent_rate
                              ).toFixed(2)}
                            </span>
                            /month
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-gray-50 rounded-md p-2 border border-gray-200 text-sm mb-3 ">
                    <p className="font-semibold text-green-600 mb-1">
                      Contact:
                    </p>
                    {property.property_manager ? (
                      <div className="flex items-center space-x-6">
                        <a
                          href={`tel:${property.property_manager.manager.phone_number}`}
                          className="text-blue-600"
                        >
                          <FaPhone className="w-5 h-5" />
                        </a>
                        <a
                          href={`https://wa.me/${property.property_manager.manager.phone_number}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-600"
                        >
                          <FaWhatsapp className="w-5 h-5" />
                        </a>
                        <a
                          href={`sms:${property.property_manager.manager.phone_number}`}
                          className="text-gray-600"
                        >
                          <TiMessages className="w-5 h-5" />
                        </a>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-6">
                        <a
                          href={`tel:${property.landlord_contact.phone_number}`}
                          className="text-blue-600"
                        >
                          <FaPhone className="w-5 h-5" />
                        </a>
                        <a
                          href={`https://wa.me/${property.landlord_contact.phone_number}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-600"
                        >
                          <FaWhatsapp className="w-5 h-5" />
                        </a>
                        <a
                          href={`sms:${property.landlord_contact.phone_number}`}
                          className="text-gray-600"
                        >
                          <TiMessages className="w-5 h-5" />
                        </a>
                      </div>
                    )}
                  </div>

                  {property.amenities && property.amenities.length > 0 && (
                    <div className="mb-3">
                      <h3 className="text-lg font-semibold mb-2 text-green-600 border-b border-green-200 pb-1">
                        Amenities:
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {(expandedAmenities[property.id]
                          ? property.amenities
                          : property.amenities.slice(0, 3)
                        ).map((amenity) => (
                          <span
                            key={amenity.id}
                            className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
                          >
                            {highlightSearchTerm(amenity.name, searchTerm)}
                          </span>
                        ))}
                        {property.amenities.length > 3 && (
                          <button
                            onClick={() => toggleAmenities(property.id)}
                            className="text-green-900 text-xs font-medium underline cursor-pointer"
                          >
                            {expandedAmenities[property.id]
                              ? "Less"
                              : "More..."}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const CountryCodeSelect = ({ value, onChange }) => {
  const countryCodes = [
    { code: '+1', country: 'USA' },
    { code: '+44', country: 'UK' },
    { code: '+91', country: 'India' },
    { code: '+254', country: 'Kenya' },
    { code: '+27', country: 'South Africa' },
    { code: '+234', country: 'Nigeria' },
    // Add more country codes as needed
  ];

  return (
    <select
      value={value}
      onChange={onChange}
      className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    >
      {countryCodes.map((country) => (
        <option key={country.code} value={country.code}>
          {country.code} ({country.country})
        </option>
      ))}
    </select>
  );
};

const EmployManager = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [formData, setFormData] = useState({
    national_id: '',
    country_code: '+254', // Default to Kenya's country code
    phone_number: '',
    first_name: '',
    last_name: '',
    management_start_date: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { national_id, phone_number, first_name, last_name, management_start_date } = formData;
    if (!national_id || !phone_number || !management_start_date) {
      setError('National ID, Phone Number, and Management Start Date are required.');
      return false;
    }
    if (first_name && first_name.length < 2) {
      setError('First name must be at least 2 characters long.');
      return false;
    }
    if (last_name && last_name.length < 2) {
      setError('Last name must be at least 2 characters long.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const submitData = {
        ...formData,
        phone_number: `${formData.country_code}${formData.phone_number}`,
        property_id: propertyId
      };

      await axiosInstance.post(
        '/landlord/employ-manager/',
        submitData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setSuccess('Manager employed successfully!');
      navigate(-1);
      setFormData({
        national_id: '',
        country_code: '+254',
        phone_number: '',
        first_name: '',
        last_name: '',
        management_start_date: '',
      });
    } catch (error) {
      console.error('Failed to employ manager:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while employing the manager');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 flex justify-center items-center">
      <div className="container mx-auto">
        <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Employ Property Manager</h2>
          <div className="mb-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
            <p className="font-bold">
              When hiring a manager, they should use their phone number and national ID as their initial password to log in. This lets them manage tasks like recording rent and maintenance costs. For security reasons, they should immediately change their password so only they know it.
            </p>
          </div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {success && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{success}</span>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="national_id" className="block text-gray-700 font-bold mb-2">
                National ID
              </label>
              <input
                type="text"
                id="national_id"
                name="national_id"
                value={formData.national_id}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone_number" className="block text-gray-700 font-bold mb-2">
                Phone Number
              </label>
              <div className="flex">
                <CountryCodeSelect
                  value={formData.country_code}
                  onChange={(e) => handleChange({ target: { name: 'country_code', value: e.target.value } })}
                />
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-2"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="first_name" className="block text-gray-700 font-bold mb-2">
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="last_name" className="block text-gray-700 font-bold mb-2">
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="management_start_date" className="block text-gray-700 font-bold mb-2">
                Management Start Date
              </label>
              <input
                type="date"
                id="management_start_date"
                name="management_start_date"
                value={formData.management_start_date}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              disabled={loading}
            >
              {loading ? 'Employing...' : 'Employ Manager'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployManager;
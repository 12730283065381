import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './utils/axiosInstance';
import { jwtDecode } from 'jwt-decode';
import { useSearch } from './SearchContext';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { setSearchTerm, setIsSearching } = useSearch();
  const [localSearchTerm, setLocalSearchTerm] = useState('');

  const accessToken = localStorage.getItem('accessToken');
  const isLoggedIn = !!accessToken;

  let isLandlord = false;
  let isPropertyManager = false;
  let hasBothRoles = false;

  if (isLoggedIn && accessToken) {
    const decodedToken = jwtDecode(accessToken);
    isLandlord = !!decodedToken.landlord_id;
    isPropertyManager = decodedToken.rental_property_manager_ids && decodedToken.rental_property_manager_ids.length > 0;
    hasBothRoles = isLandlord && isPropertyManager;
  }

  const currentPath = location.pathname;

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      await axiosInstance.post(
        '/authentication/logout/',
        { refresh: refreshToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error logging out:', error);
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/');
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const getHomeLink = () => {
    if (!isLoggedIn) return '/';
    if (isLandlord) return '/landlord/home';
    if (isPropertyManager) return '/property-manager/home';
    return '/resource-gateway';
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(localSearchTerm);
    setIsSearching(true);
    navigate('/');
  };

  const handleHomeClick = () => {
    setSearchTerm('');
    setIsSearching(false);
    setLocalSearchTerm('');
  };

  return (
    <>
      <nav className="bg-green-600 shadow-lg fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center flex-wrap">
          <Link
            to={getHomeLink()}
            className="text-white font-bold text-xl hover:bg-green-500 hover:text-white transition-colors duration-300 px-2 py-1 rounded"
            onClick={handleHomeClick}
          >
            Home
          </Link>

          <form onSubmit={handleSearch} className="relative mx-2 flex-1 min-w-0">
            <input
              type="text"
              value={localSearchTerm}
              onChange={(e) => setLocalSearchTerm(e.target.value)}
              placeholder="Find vacant rentals..."
              className="w-full border border-gray-400 rounded-full py-2 px-4 text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 transition-colors duration-300 hover:ring-yellow-200"
            />
            <button type="submit" className="absolute right-0 top-0 mt-2 mr-4">
              <svg className="h-6 w-6 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
              </svg>
            </button>
          </form>

          <button
            type="button"
            className="md:hidden text-white hover:bg-green-500 hover:text-white focus:outline-none transition-colors duration-300 px-2 py-1 rounded"
            onClick={toggleMenu}
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {isOpen ? (
                <path fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              ) : (
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              )}
            </svg>
          </button>

          <div
            className={`w-full ${isOpen ? 'block' : 'hidden'} md:flex md:items-center md:w-auto z-50`}
            ref={menuRef}
          >
            <div className="flex flex-col md:flex-row md:space-x-4 mt-2 md:mt-0 w-full text-left">
              {isLoggedIn ? (
                <>
                  {isPropertyManager && !isLandlord && (
                    <Link to="/createproperty" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                      Be a Landlord
                    </Link>
                  )}
                  {hasBothRoles && currentPath === '/property-manager/home' && (
                    <Link to="/landlord/home" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                      Switch to Landlord Role
                    </Link>
                  )}
                  {hasBothRoles && currentPath === '/landlord/home' && (
                    <Link to="/property-manager/home" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                      Switch to Management
                    </Link>
                  )}
                  <Link to="/profile" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                    Profile
                  </Link>
                  <button onClick={handleLogout} className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                    Logout
                  </button>
                </>
              ) : (
                <Link to="/login" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                  Login
                </Link>
              )}

              <Link to="/blog" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                About
              </Link>
                <Link to="/contacts" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                Contacts
              </Link>

              <Link to="/termsofservice" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                Terms of Service
              </Link>
              <Link to="/pricing" className="text-white hover:bg-green-500 hover:text-white mb-2 md:mb-0 font-bold transition-colors duration-300 py-2 px-4 rounded-md text-center">
                Pricing
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="h-[64px]"></div>
      <style jsx>{`
        body::before {
          content: '';
          display: block;
          height: 64px;
        }
      `}</style>
    </>
  );
};

export default Navbar;
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ResourceGateway = () => {
  const navigate = useNavigate();

  const handleRoleSelection = (role) => {
    localStorage.setItem('userRole', role);
    navigate(role === 'landlord' ? '/landlord/home' : '/property-manager/home');
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Select Your Role</h2>
        <p className="text-gray-600 mb-6 text-center">
          You have access to both landlord and property manager features. Please choose how you'd like to proceed:
        </p>
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => handleRoleSelection('landlord')}
            className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-300 text-lg font-semibold focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            Continue as Landlord
          </button>
          <button
            onClick={() => handleRoleSelection('property-manager')}
            className="bg-green-600 text-white py-3 px-6 rounded-lg hover:bg-green-700 transition-colors duration-300 text-lg font-semibold focus:outline-none focus:ring-4 focus:ring-green-300"
          >
            Continue as Property Manager
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourceGateway;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../Spinner';

const RentalPropertyIncome = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const [financialSummary, setFinancialSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFinancialSummary = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          setError('Access token not found. Please log in.');
          setLoading(false);
          navigate('/login');
          return;
        }

        const response = await axiosInstance.get(`/landlord/properties/${propertyId}/financial-summary`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setFinancialSummary(response.data);
      } catch (error) {
        console.error('Failed to fetch financial summary:', error);
        if (error.response?.status === 401) {
          setError('Your session has expired. Please log in again.');
          navigate('/login');
        } else {
          setError('An error occurred while fetching the financial summary. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialSummary();
  }, [propertyId, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-4">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800">Financial Summary for {financialSummary.name}</h2>
      <div className="mb-6">
        <p className="text-lg text-gray-700">
          <strong>Total Tenant Balances:</strong> {financialSummary.total_tenant_balances?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'KES',
          })}
        </p>
      </div>
      <div>
        <h3 className="text-2xl font-medium mb-4 text-gray-800">Monthly Payments Summary</h3>
        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-200 px-4 py-3 text-left text-gray-700">Month</th>
              <th className="border border-gray-200 px-4 py-3 text-left text-gray-700">Collected Rent</th>
            </tr>
          </thead>
          <tbody>
            {financialSummary.monthly_payments && Object.entries(financialSummary.monthly_payments).map(([month, amount]) => (
              <tr key={month} className="hover:bg-gray-50 transition-colors duration-200">
                <td className="border border-gray-200 px-4 py-3 text-gray-700">
                  <Link to={`/property/${propertyId}/payments/${month.split('/')[0]}/${month.split('/')[1]}`} className="text-blue-600 hover:underline">
                    {month}
                  </Link>
                </td>
                <td className="border border-gray-200 px-4 py-3 text-gray-700">{amount.toLocaleString('en-US', { style: 'currency', currency: 'KES' })}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RentalPropertyIncome;

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../Spinner';

const PaymentHistory = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPayment, setEditingPayment] = useState(null);

  const fetchPaymentHistory = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const response = await axiosInstance.get(`/management/tenants/${tenantId}/payment-history/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setPaymentHistory(response.data);
    } catch (error) {
      console.error('Failed to fetch payment history:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while fetching payment history');
      }
    } finally {
      setLoading(false);
    }
  }, [tenantId, navigate]);

  const fetchPaymentModes = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const response = await axiosInstance.get('/management/payment-modes/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log('Payment modes response:', response.data);

      const modesArray = Object.entries(response.data).map(([key, value]) => ({
        id: key,
        name: value,
      }));

      setPaymentModes(modesArray);
    } catch (error) {
      console.error('Failed to fetch payment modes:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while fetching payment modes');
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchPaymentHistory();
    fetchPaymentModes();
  }, [fetchPaymentHistory, fetchPaymentModes]);

  const handleEditClick = (payment) => {
    // Clone payment object to prevent direct mutation
    const { date_recorded, ...editablePayment } = payment; // Exclude date_recorded field
    setEditingPayment(editablePayment);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditingPayment(null);
  };

  const handlePaymentUpdate = async (updatedPayment) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      // Log the payment data before sending it
      console.log('Updating payment:', updatedPayment);

      await axiosInstance.put(`/management/payments/${updatedPayment.id}/`, updatedPayment, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setPaymentHistory(paymentHistory.map(payment =>
        payment.id === updatedPayment.id ? updatedPayment : payment
      ));
      handleModalClose();
    } catch (error) {
      console.error('Failed to update payment:', error);
      setError(error.response?.data?.detail || 'An error occurred while updating the payment');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Payment History</h2>
      {paymentHistory.length === 0 ? (
        <p className="text-gray-600">No payment history found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b">Date Paid</th>
                <th className="py-2 px-4 border-b">Amount Paid</th>
                <th className="py-2 px-4 border-b">Payment Mode</th>
                <th className="py-2 px-4 border-b">Payment details</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.map((payment) => (
                <tr key={payment.id} className="hover:bg-gray-50">
                  <td className="py-4 px-4 border-b">{payment.date_paid}</td>
                  <td className="py-4 px-4 border-b">Ksh {payment.amount_paid}</td>
                  <td className="py-4 px-4 border-b">{payment.mode_of_payment}</td>
                  <td className="py-4 px-4 border-b">{payment.payment_details}</td>
                  <td className="py-4 px-4 border-b">
                    <button 
                      onClick={() => handleEditClick(payment)} 
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Edit Payment</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              handlePaymentUpdate(editingPayment);
            }}>
              <div className="mb-4">
                <label htmlFor="date_paid" className="block text-sm font-medium text-gray-700">Date Paid</label>
                <input
                  type="date"
                  id="date_paid"
                  value={editingPayment.date_paid}
                  onChange={(e) => setEditingPayment({...editingPayment, date_paid: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="amount_paid" className="block text-sm font-medium text-gray-700">Amount Paid</label>
                <input
                  type="number"
                  id="amount_paid"
                  value={editingPayment.amount_paid}
                  onChange={(e) => setEditingPayment({...editingPayment, amount_paid: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="mode_of_payment" className="block text-sm font-medium text-gray-700">Payment Mode</label>
                <select
                  id="mode_of_payment"
                  value={editingPayment.mode_of_payment}
                  onChange={(e) => setEditingPayment({...editingPayment, mode_of_payment: e.target.value})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="" disabled>Select a mode</option>
                  {paymentModes.map(mode => (
                    <option key={mode.id} value={mode.id}>
                      {mode.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="payment_details" className="block text-sm font-medium text-gray-700">Payment Details</label>
                <textarea
                  id="payment_details"
                  value={editingPayment.payment_details}
                  onChange={(e) => setEditingPayment({...editingPayment, payment_details: e.target.value})}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button 
                  type="button" 
                  onClick={handleModalClose} 
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;

import React from 'react';

const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-screen spinner-overlay">
      <div className="relative">
        <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
        <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin enhanced-spin"></div>
      </div>
      <span className="loading-text">Loading...</span>
      <style jsx>{`
        .spinner-overlay {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
          z-index: 1000; /* Ensure it's on top of other content */
        }

        .relative {
          position: relative;
        }

        .h-24 {
          height: 6rem;
          width: 6rem;
        }

        .w-24 {
          width: 6rem;
        }

        .rounded-full {
          border-radius: 50%;
        }

        .border-t-8 {
          border-top-width: 8px;
        }

        .border-b-8 {
          border-bottom-width: 8px;
        }

        .border-gray-200 {
          border-color: #e2e8f0; /* Light grey */
        }

        .border-green-900 {
          border-color: #98FF98; /* Blue */
        }

        .animate-spin {
          animation: spin 1.5s linear infinite;
        }

        .enhanced-spin {
          animation: enhanced-spin 1.5s ease-in-out infinite;
          border-top-color: #3182ce; /* Enhanced blue */
          border-bottom-color: transparent;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        @keyframes enhanced-spin {
          0% { transform: rotate(0deg); }
          50% { transform: rotate(180deg); }
          100% { transform: rotate(360deg); }
        }

        .loading-text {
          margin-top: 20px;
          font-size: 1.5rem;
          color: #3182ce; /* Enhanced blue */
          animation: fadeIn 2s ease-in-out infinite;
        }

        @keyframes fadeIn {
          0%, 100% { opacity: 0; }
          50% { opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default Spinner;

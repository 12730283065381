import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { FaPlus, FaChevronRight, FaEllipsisH, FaBuilding, FaUserTie,FaMoneyBillAlt } from "react-icons/fa";
import Spinner from "../Spinner";

const Landlordhomepage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedAmenities, setExpandedAmenities] = useState({});
  const [message, setMessage] = useState(location.state?.message || "");

  const fetchProperties = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        navigate("/login");
        return;
      }

      const response = await axiosInstance.get("/landlord/properties", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setProperties(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch properties:", error);
      if (error.response?.status === 401) {
        navigate("/login");
      } else {
        setError(
          error.response?.data?.detail ||
            "An error occurred while fetching properties"
        );
      }
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchProperties();

    const timeoutId = setTimeout(() => {
      setMessage("");
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [fetchProperties, message]);

  const handleAddProperty = () => {
    navigate("/add-property");
  };

  const toggleAmenities = (propertyId) => {
    setExpandedAmenities((prev) => ({
      ...prev,
      [propertyId]: !prev[propertyId],
    }));
  };

  if (loading) return <Spinner />;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="container mx-auto">
        {message && <SuccessMessage message={message} />}
       
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {properties.map((property) => (
            <PropertyCard
              key={property.id}
              property={property}
              expandedAmenities={expandedAmenities}
              toggleAmenities={toggleAmenities}
              message={message}
            />
          ))}
          <AddPropertyCard onClick={handleAddProperty} />
        </div>
      </div>
    </div>
  );
};

const ErrorDisplay = ({ error }) => (
  <div className="flex items-center justify-center h-screen">
    <p className="text-red-600 text-2xl font-semibold">{error}</p>
  </div>
);

const SuccessMessage = ({ message }) => (
  <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
    <span className="block sm:inline">{message}</span>
  </div>
);

const PropertyCard = ({ property, expandedAmenities, toggleAmenities, message }) => {
  const highlightProperty = message && message.includes(property.name);

  return (
    <div className={`bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 
      ${highlightProperty ? "border-4 border-green-500 transform scale-105" : ""}`}>
      <div className="p-6">
        <PropertyHeader name={property.name} id={property.id} />
        <div className="space-y-4">
          <PropertyInfoItem
            icon={<FaBuilding className="text-blue-500" />}
            label="Total Rental Units"
            value={property.total_units}
            link={`/property/${property.id}/units`}
          />
          <PropertyInfoItem
            icon={<FaMoneyBillAlt className="text-green-500" />}
            label="Expenses"
            link={`/property/${property.id}/expenses`}
          />
          <PropertyInfoItem
            icon={<FaMoneyBillAlt className="text-indigo-500" />}
            label="Financial Status"
            link={`/property/${property.id}/income`}
          />
          <LocationInfo location={property.location} />
          <AmenitiesList
            amenities={property.amenities}
            expanded={expandedAmenities[property.id]}
            onToggle={() => toggleAmenities(property.id)}
          />
          <ManagerInfo manager={property.manager} propertyId={property.id} />
        </div>
      </div>
    </div>
  );
};

const PropertyHeader = ({ name, id }) => (
  <Link to={`/editproperty/${id}`} className="flex items-center justify-between text-2xl font-bold text-blue-600 hover:text-blue-800 transition-colors duration-300 mb-4">
    <span>{name}</span>
    <FaChevronRight />
  </Link>
);

const PropertyInfoItem = ({ icon, label, value, link }) => (
  <Link to={link} className="flex items-center space-x-2 text-lg hover:bg-gray-100 p-2 rounded transition-colors duration-300">
    {icon}
    <span className="font-semibold">{label}:</span>
    {value && <span>{value}</span>}
    <FaChevronRight className="ml-auto" />
  </Link>
);

const LocationInfo = ({ location }) => (
  <div className="mt-4">
    <p className="text-lg text-gray-700 mb-2">
      <strong>Location:</strong> {location}
    </p>
  </div>
);

const AmenitiesList = ({ amenities, expanded, onToggle }) => (
  <div className="mt-4">
    <p className="text-lg font-semibold text-gray-700 mb-2 flex items-center">
      Amenities:
      {amenities && amenities.length > 3 && (
        <button className="ml-2 text-green-600 hover:text-green-800 focus:outline-none" onClick={onToggle}>
          <FaEllipsisH />
        </button>
      )}
    </p>
    <ul className="list-disc pl-6">
      {amenities && amenities.length > 0 ? (
        <>
          {amenities.slice(0, 3).map((amenity, index) => (
            <li key={index} className="text-gray-600">{amenity.name}</li>
          ))}
          {amenities.length > 3 && (
            expanded ? (
              <>
                {amenities.slice(3).map((amenity, index) => (
                  <li key={index + 3} className="text-gray-600">{amenity.name}</li>
                ))}
                <li className="text-gray-600">
                  <button className="text-green-600 hover:text-green-800 underline focus:outline-none" onClick={onToggle}>
                    Show Less
                  </button>
                </li>
              </>
            ) : (
              <li className="text-gray-600">
                <button className="text-green-600 hover:text-green-800 underline focus:outline-none" onClick={onToggle}>
                  View More
                </button>
              </li>
            )
          )}
        </>
      ) : (
        <li className="text-gray-600">None</li>
      )}
    </ul>
  </div>
);

const ManagerInfo = ({ manager, propertyId }) => (
  <div className="mt-4 bg-blue-50 p-4 rounded-lg">
    <p className="text-lg font-semibold text-gray-700 mb-2">
      <FaUserTie className="inline-block mr-2 text-blue-500" />
      Property Manager
    </p>
    {manager ? (
      <>
        <p>Name: {manager.name}</p>
        <p>Phone: {manager.phone_number}</p>
        <Link to={`/property/${propertyId}/fire-manager`} className="text-green-600 hover:text-green-800 underline block mt-2">
          View more about manager
        </Link>
      </>
    ) : (
      <Link to={`/property/${propertyId}/employ-manager`} className="text-blue-600 hover:text-blue-800 underline">
        Employ Property Manager
      </Link>
    )}
  </div>
);

const AddPropertyCard = ({ onClick }) => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer hover:shadow-xl transition-shadow duration-300" onClick={onClick}>
    <div className="p-6 flex flex-col items-center justify-center h-full">
      <FaPlus className="text-5xl text-green-600 mb-4" />
      <div className="text-xl font-semibold text-green-600">
        Add Another Property
      </div>
    </div>
  </div>
);

export default Landlordhomepage;
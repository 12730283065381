import axios from 'axios';

export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axios.post('https://www.vacantrentalhouses.com/api/authentication/api/token/refresh/', {
      refresh: refreshToken,
    });

    const { access, refresh } = response.data;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh); // Update the refresh token as well
    return access;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <article className="max-w-5xl mx-auto px-4 py-12 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-50 to-white">
      <header>
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-8 sm:mb-12 text-center">
          VacantRentalHouses: Connecting Real Homes with Real People
        </h1>
      </header>
      
      <section className="mb-12 sm:mb-16 text-center">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 sm:mb-6">Welcome to a new era of rental housing</h2>
        <p className="text-lg sm:text-xl text-gray-700 max-w-3xl mx-auto">
          Tired of outdated listings and endless property management hassles? We hear you. That's why we created VacantRentalHouses - a platform that makes finding and managing rental properties easier than ever.
        </p>
      </section>

      <section className="mb-12 sm:mb-16 bg-blue-100 rounded-xl p-6 sm:p-8 shadow-lg">
        <h2 className="text-2xl sm:text-3xl font-bold text-blue-800 mb-4 sm:mb-6">For renters: Your search ends here</h2>
        <p className="text-base sm:text-lg text-blue-700 mb-6 sm:mb-8">
          We've all been there - falling for a perfect apartment online, only to find it's been rented for weeks. Not anymore. Our promise is simple: every listing you see is available right now. No disappointments, no wasted time. Just real homes, ready for you to move in. Plus, you're just a quick message away from speaking directly with the landlord or agent.
        </p>
        <Link to="/" className="inline-block bg-blue-600 text-white font-bold px-6 py-3 sm:px-8 sm:py-4 rounded-full text-base sm:text-lg hover:bg-blue-700 transform hover:scale-105 transition duration-300 shadow-md">
          Find Your New Home
        </Link>
      </section>

      <section className="mb-12 sm:mb-16 bg-green-100 rounded-xl p-6 sm:p-8 shadow-lg">
        <h2 className="text-2xl sm:text-3xl font-bold text-green-800 mb-4 sm:mb-6">Property owners, welcome to smarter management</h2>
        <p className="text-base sm:text-lg text-green-700 mb-4">
          Managing rentals shouldn't be a full-time job. With VacantRentalHouses, you can efficiently run your properties from anywhere in the world. Here's what we offer:
        </p>
        <ul className="list-disc list-inside text-base sm:text-lg text-green-700 mb-6 space-y-2">
          <li>Real-time financial updates</li>
          <li>Instant occupancy insights</li>
          <li>Simplified expense tracking</li>
          <li>Employ property manager through our platform</li>
          <li>Complimentary marketing for your vacant properties</li>
        </ul>
        <p className="text-base sm:text-lg text-green-700 mb-6">
          It's like having a highly efficient assistant working round the clock.
        </p>
        <Link to="/register" className="inline-block bg-green-600 text-white font-bold px-6 py-3 sm:px-8 sm:py-4 rounded-full text-base sm:text-lg hover:bg-green-700 transform hover:scale-105 transition duration-300 shadow-md">
          Join as a Property Owner
        </Link>
      </section>

      <section className="mb-12 sm:mb-16">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 text-center">Why choose VacantRentalHouses?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {[
            { icon: "🕒", title: "Always current listings", desc: "Saving you valuable time" },
            { icon: "🔧", title: "Intuitive property management tools", desc: "For easier management" },
            { icon: "🤝", title: "Swift connections", desc: "Between renters and owners" },
            { icon: "🌍", title: "Remote management", desc: "Manage properties with ease" },
            { icon: "📢", title: "Free marketing", desc: "For your vacant properties" },
          ].map((item, index) => (
            <div key={index} className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300">
              <span className="text-3xl sm:text-4xl mb-3 sm:mb-4" role="img" aria-label={item.title}>{item.icon}</span>
              <h3 className="text-lg sm:text-xl font-semibold mb-2">{item.title}</h3>
              <p className="text-sm sm:text-base text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center bg-purple-100 rounded-xl p-6 sm:p-8 shadow-lg">
        <h2 className="text-2xl sm:text-3xl font-bold text-purple-800 mb-4 sm:mb-6">Ready to transform your rental experience?</h2>
        <p className="text-base sm:text-lg text-purple-700 mb-6 sm:mb-8 max-w-3xl mx-auto">
          Whether you're searching for your ideal rental or aiming to streamline your property management, VacantRentalHouses is here to help. Join us in making the rental process smoother for everyone.
        </p>
        <Link to="/register" className="inline-block bg-purple-600 text-white font-bold px-8 py-3 sm:px-10 sm:py-4 rounded-full text-lg sm:text-xl hover:bg-purple-700 transform hover:scale-105 transition duration-300 shadow-md">
          Get Started Today
        </Link>
      </section>
    </article>
  );
};

export default Blog;
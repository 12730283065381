import React from 'react';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/register');
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen py-8 sm:py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-center mb-6 sm:mb-10 text-blue-700">
          Efficient Property Management at an Unbeatable Price!
        </h1>
        
        <div className="bg-white shadow-2xl rounded-2xl p-6 sm:p-8 max-w-2xl mx-auto">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 sm:mb-6 text-green-600">
            Manage Globally, Pay Just 1.5% per Occupied Unit
          </h2>
          
          <ul className="text-lg sm:text-xl space-y-3 sm:space-y-4 mb-6 sm:mb-8">
            <li className="flex items-center">
              <span className="text-green-500 text-2xl sm:text-3xl mr-2">✓</span> Real-time monitoring of expenses, rent, and vacancies
            </li>
            <li className="flex items-center">
              <span className="text-green-500 text-2xl sm:text-3xl mr-2">✓</span> Global vacancy broadcasts for effortless tenant attraction
            </li>
            <li className="flex items-center">
              <span className="text-green-500 text-2xl sm:text-3xl mr-2">✓</span> Worldwide access for remote management
            </li>
            <li className="flex items-center">
              <span className="text-green-500 text-2xl sm:text-3xl mr-2">✓</span> Customizable rent reminders via SMS, WhatsApp, and more
            </li>
          </ul>
          
          <div className="bg-blue-100 p-4 sm:p-6 rounded-xl mb-6 sm:mb-8">
            <h3 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4 text-blue-800">Incredible Value Example:</h3>
            <p className="text-base sm:text-xl mb-2">• Occupied unit rent: KSH 5,000</p>
            <p className="text-base sm:text-xl mb-2">• Your cost: Only KSH 75 per month!</p>
            <p className="text-xl sm:text-2xl font-bold text-green-600 mt-3 sm:mt-4">
              That's just 1.5% for complete management efficiency!
            </p>
          </div>
          
          <p className="text-xl sm:text-2xl font-semibold mb-6 text-center">
            Streamline Your Workflow<br/>
            Maximize Your Profits<br/>
            Manage with Ease - Anytime, Anywhere!
          </p>
          
          <div className="text-center">
            <button 
              className="bg-blue-600 hover:bg-blue-700 text-white text-lg sm:text-2xl font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-full transition duration-300"
              onClick={handleButtonClick}
            >
              Start Efficient Management for Just 1.5% Today!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-2xl overflow-hidden">
        <div className="px-6 py-8 sm:px-10 sm:py-12">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-2">Terms of Service</h1>
          <p className="text-sm text-gray-500 mb-8">Last Updated: [25TH JULY 2024]</p>

          <div className="space-y-10">
            {sections.map((section, index) => (
              <Section key={index} {...section} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, content }) => (
  <div>
    <h2 className="text-2xl font-bold text-gray-800 mb-3">{title}</h2>
    <div className="text-gray-600 space-y-3">{content}</div>
  </div>
);

const sections = [
  {
    title: "1. Using Our Service",
    content: (
      <>
        <p>By using our service, you agree to these terms and our Privacy Policy. If you don't agree, please don't use it. We may change these terms at any time. We'll notify you of significant changes.</p>
      </>
    )
  },
  {
    title: "2. Your Account",
    content: (
      <>
        <p>To use some features, you need an account. You must:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Provide accurate and complete information</li>
          <li>Keep your account information up-to-date</li>
          <li>Maintain the security of your account</li>
          <li>Notify us immediately of any unauthorized use</li>
          <li>Be at least 18 years old</li>
        </ul>
        <p>We may suspend or terminate accounts that violate our terms or for any other reason at our discretion.</p>
      </>
    )
  },
  {
    title: "3. For Landlords",
    content: (
      <>
        <p>As a landlord, you can:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Add and manage rental units</li>
          <li>Manage tenants</li>
          <li>Hire and manage property managers</li>
        </ul>
        <p>You're responsible for the accuracy of the information you provide and for complying with all applicable laws and regulations related to property rental.</p>
      </>
    )
  },
  {
    title: "4. For Tenants",
    content: (
      <>
        <p>As a tenant or potential tenant, you can:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>View available units in real-time</li>
          <li>Contact landlords or managers through our service</li>
          <li>Request additional information about properties</li>
        </ul>
        <p>You agree to provide accurate information and to use the service in good faith for its intended purpose of finding rental properties.</p>
      </>
    )
  },
  {
    title: "5. Listing Rental Units",
    content: (
      <>
        <p>We will never share your data, except for vacant rental units, with any third party. Only you, the landlord, can share your data with entities like banks or tax authorities. Our platform helps you find tenants and manage rentals. Vacant units and phone numbers are shared publicly only for tenant inquiries.</p>
      </>
    )
  },
  {
    title: "6. Fees and Payments",
    content: (
      <>
        <p>We may charge for some features. When applicable:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Fees will be clearly disclosed before you incur them</li>
          <li>We use a third-party payment processor</li>
          <li>We're not responsible for payment processing issues</li>
          <li>All fees are non-refundable unless otherwise stated</li>
        </ul>
        <p>You agree to pay all fees and taxes associated with your use of our service.</p>
      </>
    )
  },
  {
    title: "7. Prohibited Activities",
    content: (
      <>
        <p>You agree not to:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Use our service for anything illegal or unauthorized</li>
          <li>Interfere with or disrupt the service or servers</li>
          <li>Attempt to gain unauthorized access to any part of the service</li>
          <li>Use the service to transmit harmful code or content</li>
          <li>Impersonate any person or entity</li>
          <li>Harass, abuse, or harm another person</li>
          <li>Collect or store personal data about other users without their permission</li>
        </ul>
        <p>Violation of these prohibitions may result in termination of your account and potential legal action.</p>
      </>
    )
  },
  {
    title: "8. Intellectual Property",
    content: (
      <>
        <p>Our service content (including text, graphics, logos, and software) is protected by copyright and other laws. You may not use, copy, or distribute our content without our permission.</p>
      </>
    )
  },
  {
    title: "9. Account Termination",
    content: (
      <>
        <p>We can suspend or terminate your account at any time, for any reason, without notice. Upon termination, your right to use the service will immediately cease.</p>
      </>
    )
  },
  {
    title: "10. Limitation of Liability",
    content: (
      <>
        <p>To the fullest extent permitted by law, we are not liable for:</p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Any indirect, incidental, special, consequential, or punitive damages</li>
          <li>Any loss of profits, revenue, data, or goodwill</li>
          <li>Any damages related to your access to, use of, or inability to access or use the service</li>
          <li>Any conduct or content of any third party on the service</li>
          <li>Any unauthorized access, use, or alteration of your transmissions or content</li>
          <li>Any bugs, viruses, or other harmful code that may be transmitted through our service</li>
          <li>Any interruption or cessation of transmission to or from our service</li>
          <li>Any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content</li>
        </ul>
        <p>This limitation applies regardless of the legal theory on which the claim is based, even if we have been advised of the possibility of such damages.</p>
      </>
    )
  },
  {
    title: "11. Disclaimer of Warranties",
    content: (
      <>
        <p>Our service is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee that the service will be uninterrupted, secure, or error-free.</p>
      </>
    )
  },
  {
    title: "12. Governing Law",
    content: (
      <>
        <p>These terms are governed by the laws of Kenya. Any disputes arising from these terms or your use of the service will be subject to the exclusive jurisdiction of the courts of Kenya.</p>
      </>
    )
  },
  {
    title: "13. Changes to Terms",
    content: (
      <>
        <p>We may modify these terms at any time. We'll notify you of significant changes. Your continued use of the service after changes constitutes acceptance of the new terms.</p>
      </>
    )
  },
  {
    title: "14. Contact",
    content: (
      <>
        <p>For questions about these terms, please contact us at <a href="mailto:vacantrentalhouses@gmail.com" className="text-blue-600 hover:underline">vacantrentalhouses@gmail.com</a>.</p>
      </>
    )
  },
];

export default TermsOfService;

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const AddTenant = () => {
  const { unitId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date_tenancy_starts: '',
    tenant_name: '',
    national_id_number: '',
    phone: '',
    emergency_contact_name: '',
    emergency_contact_phone: '',
    emergency_contact_relationship: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const parsedUnitId = parseInt(unitId);
    if (isNaN(parsedUnitId)) {
      console.error('Invalid unit ID:', unitId);
      setError('Invalid unit ID.');
      setLoading(false);
      return;
    }

    try {
      const payload = {
        rental_unit_occupied: parsedUnitId,
        ...formData,
      };

      const response = await axiosInstance.post('/management/tenants/', payload);
      console.log('Tenant added:', response.data);
      navigate(-1);
    } catch (error) {
      console.error('Error adding tenant:', error);
      setError('Failed to add tenant. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-8">
      <h2 className="text-2xl mb-4">Add Tenant to Unit</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <p className="mb-4 text-red-600 text-lg font-semibold">
        Note: If a tenant moves in after the 10th, they'll pay only for the remaining days, not the whole month
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="date_tenancy_starts" className="block text-gray-700 text-sm font-bold mb-2">Date Tenancy Starts</label>
          <input
            type="date"
            id="date_tenancy_starts"
            name="date_tenancy_starts"
            placeholder="Required"
            value={formData.date_tenancy_starts}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="tenant_name" className="block text-gray-700 text-sm font-bold mb-2">Tenant Name</label>
          <input
            type="text"
            id="tenant_name"
            name="tenant_name"
            placeholder="Required"
            value={formData.tenant_name}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="national_id_number" className="block text-gray-700 text-sm font-bold mb-2">National ID Number</label>
          <input
            type="text"
            id="national_id_number"
            name="national_id_number"
            value={formData.national_id_number}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="emergency_contact_name" className="block text-gray-700 text-sm font-bold mb-2">Emergency Contact Name</label>
          <input
            type="text"
            id="emergency_contact_name"
            name="emergency_contact_name"
            value={formData.emergency_contact_name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="emergency_contact_phone" className="block text-gray-700 text-sm font-bold mb-2">Emergency Contact Phone</label>
          <input
            type="text"
            id="emergency_contact_phone"
            name="emergency_contact_phone"
            value={formData.emergency_contact_phone}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="emergency_contact_relationship" className="block text-gray-700 text-sm font-bold mb-2">Emergency Contact Relationship</label>
          <input
            type="text"
            id="emergency_contact_relationship"
            name="emergency_contact_relationship"
            value={formData.emergency_contact_relationship}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          {loading ? 'Adding...' : 'Add Tenant'}
        </button>
      </form>
    </div>
  );
};

export default AddTenant;

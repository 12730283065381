import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const CreateProperty = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    amenities: [],
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleCount, setVisibleCount] = useState(4);

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await axiosInstance.get('landlord/amenities/');
        setAmenities(response.data);
      } catch (error) {
        console.error('Error fetching amenities:', error);
      }
    };

    fetchAmenities();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        amenities: [...formData.amenities, value],
      });
    } else {
      setFormData({
        ...formData,
        amenities: formData.amenities.filter((amenity) => amenity !== value),
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setVisibleCount(4);
  };

  const filteredAmenities = amenities.filter((amenity) =>
    amenity.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleShowMore = () => {
    setVisibleCount((prevCount) => {
      if (prevCount + 4 >= filteredAmenities.length) {
        return filteredAmenities.length;
      }
      return prevCount + 4;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      await axiosInstance.post('management/createproperty/', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      
      navigate('/login');
    } catch (error) {
      setError(error.response?.data?.detail || 'An error occurred while creating the property');
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center py-12">
      <div className="bg-white shadow-md rounded-lg w-full max-w-lg p-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Create New Property</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Property Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="search">
              Search Amenities
            </label>
            <input
              type="text"
              id="search"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Search amenities..."
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Choose Amenities</label>
            <div className="overflow-y-auto max-h-48">
              {filteredAmenities.slice(0, visibleCount).map((amenity) => (
                <div key={amenity.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`amenity-${amenity.id}`}
                    name={`amenity-${amenity.id}`}
                    value={amenity.id}
                    checked={formData.amenities.includes(amenity.id.toString())}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                  />
                  <label htmlFor={`amenity-${amenity.id}`}>{amenity.name}</label>
                </div>
              ))}
            </div>
            {visibleCount < filteredAmenities.length && (
              <button
                type="button"
                onClick={handleShowMore}
                className="mt-2 text-blue-500 hover:underline focus:outline-none"
              >
                Show More
              </button>
            )}
          </div>
          {error && (
            <div className="mb-4 text-red-600">
              <p>{error}</p>
            </div>
          )}
          <div className="mb-4">
            <button
              type="submit"
              className={`w-full py-2 px-4 text-white rounded ${
                loading ? 'bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50'
              }`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create Property'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProperty;
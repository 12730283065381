import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { jwtDecode } from 'jwt-decode';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

const Signin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('authentication/api/token/', {
        username,
        password,
      });
      const { access, refresh } = response.data;

      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);

      const decodedToken = jwtDecode(access);

      if (decodedToken.landlord_id && decodedToken.rental_property_manager_ids.length > 0) {
        navigate('/resource-gateway');
      } else if (decodedToken.landlord_id) {
        navigate('/landlord/home');
      } else if (decodedToken.rental_property_manager_ids.length > 0) {
        navigate('/property-manager/home');
      } else {
        setErrorMessage('You do not have the required roles.');
      }
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 401)) {
        setErrorMessage('Invalid credentials');
      } else {
        setErrorMessage('An error occurred during login');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-6 text-center">Sign In or <strong><Link to="/register" className="text-blue-500 hover:text-blue-700">
            Register
          </Link></strong></h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700 font-semibold mb-2">
              Phone Number or Email
            </label>
            <input
            placeholder='eg +254712345678 or example@gmail.com'
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">
              Password
            </label>
            <div className="relative flex items-center">
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
              />
              <div
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-300"
          >
            Log in
          </button>
        </form>
        <div className="mt-6 text-center">
          <Link to="/register" className="text-blue-500 hover:text-blue-700">
            Register
          </Link>{' '}
          |{' '}
          <Link to="/reset-password" className="text-blue-500 hover:text-blue-700">
            Reset Password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signin;
import React, { useState } from 'react';

const RentCalculator = () => {
  const [monthlyRent, setMonthlyRent] = useState('');
  const [moveInDate, setMoveInDate] = useState('');
  const [calculatedRent, setCalculatedRent] = useState(null);

  const calculateRent = () => {
    const rent = parseFloat(monthlyRent);
    const date = new Date(moveInDate);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    if (day <= 10) {
      setCalculatedRent(rent);
    } else {
      const remainingDays = daysInMonth - day + 1;
      const proratedRent = (rent / daysInMonth) * remainingDays;
      setCalculatedRent(proratedRent.toFixed(2));
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <h2 className="text-xl font-semibold text-green-700 mb-4">Rent Calculator</h2>
      <p className="text-sm text-gray-600 mb-4">After the 10th, tenant pays for remaining days in the month.</p>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="monthly-rent">
            Monthly Rent (Ksh)
          </label>
          <input
            id="monthly-rent"
            type="number"
            value={monthlyRent}
            onChange={(e) => setMonthlyRent(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="move-in-date">
            Move-in Date
          </label>
          <input
            id="move-in-date"
            type="date"
            value={moveInDate}
            onChange={(e) => setMoveInDate(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
        <button
          onClick={calculateRent}
          className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
        >
          Calculate Rent
        </button>
      </div>
      {calculatedRent !== null && (
        <div className="mt-4 p-4 bg-green-100 rounded-md">
          <h3 className="text-lg font-semibold text-green-800">Calculated Rent: Ksh {calculatedRent}</h3>
        </div>
      )}
    </div>
  );
};

export default RentCalculator;
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import Spinner from '../Spinner';

const PropertyExpenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newExpense, setNewExpense] = useState({ date: '', amount: '', description: '' });
  const [updateExpense, setUpdateExpense] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const { propertyId } = useParams();

  const fetchExpenses = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`landlord/property/${propertyId}/expenses/`);
      setExpenses(response.data);
    } catch (error) {
      console.error("There was an error fetching the expenses!", error);
    } finally {
      setLoading(false);
    }
  }, [propertyId]);

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  const handleInputChange = (e, isUpdate = false) => {
    const { name, value } = e.target;
    if (isUpdate) {
      setUpdateExpense({ ...updateExpense, [name]: value });
    } else {
      setNewExpense({ ...newExpense, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const expenseToSend = {
        date: newExpense.date,
        amount: parseFloat(newExpense.amount).toFixed(2),
        description: newExpense.description
      };
      await axiosInstance.post(`landlord/property/${propertyId}/expenses/`, expenseToSend);
      setNewExpense({ date: '', amount: '', description: '' });
      fetchExpenses();
    } catch (error) {
      console.error("There was an error creating the expense!", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const expenseToUpdate = {
        ...updateExpense,
        amount: parseFloat(updateExpense.amount).toFixed(2)
      };
      await axiosInstance.put(`landlord/property/${propertyId}/expenses/${updateExpense.id}/`, expenseToUpdate);
      setShowUpdateModal(false);
      fetchExpenses();
    } catch (error) {
      console.error("There was an error updating the expense!", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`landlord/property/${propertyId}/expenses/${expenseToDelete.id}/`);
      setShowDeleteModal(false);
      fetchExpenses();
    } catch (error) {
      console.error("There was an error deleting the expense!", error);
    }
  };

  const openUpdateModal = (expense) => {
    setUpdateExpense(expense);
    setShowUpdateModal(true);
  };

  const openDeleteModal = (expense) => {
    setExpenseToDelete(expense);
    setShowDeleteModal(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Property Expenses</h1>

        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <input
            type="date"
            name="date"
            value={newExpense.date}
            onChange={handleInputChange}
            required
            className="p-2 border rounded-md"
          />
          <input
            type="number"
            name="amount"
            value={newExpense.amount}
            onChange={handleInputChange}
            step="0.01"
            min="0"
            placeholder="Amount"
            required
            className="p-2 border rounded-md"
          />
          <input
            type="text"
            name="description"
            value={newExpense.description}
            onChange={handleInputChange}
            placeholder="Description"
            required
            className="p-2 border rounded-md"
          />
          <button type="submit" className="bg-blue-500 text-white p-2 rounded-md md:col-start-3">Add Expense</button>
        </form>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 text-left">Date</th>
                <th className="py-2 px-4 text-left">Amount</th>
                <th className="py-2 px-4 text-left">Description</th>
                <th className="py-2 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense) => (
                <tr key={expense.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4">{expense.date}</td>
                  <td className="py-2 px-4">${parseFloat(expense.amount).toFixed(2)}</td>
                  <td className="py-2 px-4">{expense.description}</td>
                  <td className="py-2 px-4">
                    <div className="flex flex-wrap">
                      <button onClick={() => openUpdateModal(expense)} className="mr-2 mb-2 bg-yellow-500 text-white p-1 rounded-md">Update</button>
                      <button onClick={() => openDeleteModal(expense)} className="mb-2 bg-red-500 text-white p-1 rounded-md">Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {expenses.length === 0 && (
          <p className="text-gray-600">No expenses found for this property.</p>
        )}
      </div>

      {/* Update Modal */}
      {showUpdateModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 border w-96 shadow-lg rounded-md">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Update Expense</h3>
            <form onSubmit={handleUpdate}>
              <input
                type="date"
                name="date"
                value={updateExpense.date}
                onChange={(e) => handleInputChange(e, true)}
                required
                className="mb-2 p-2 border rounded-md w-full"
              />
              <input
                type="number"
                name="amount"
                value={updateExpense.amount}
                onChange={(e) => handleInputChange(e, true)}
                step="0.01"
                min="0"
                placeholder="Amount"
                required
                className="mb-2 p-2 border rounded-md w-full"
              />
              <input
                type="text"
                name="description"
                value={updateExpense.description}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Description"
                required
                className="mb-2 p-2 border rounded-md w-full"
              />
              <div className="mt-4 flex justify-end">
                <button type="submit" className="bg-blue-500 text-white p-2 rounded-md mr-2">Update</button>
                <button type="button" onClick={() => setShowUpdateModal(false)} className="bg-gray-300 text-black p-2 rounded-md">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-5 border w-96 shadow-lg rounded-md">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p>Are you sure you want to delete this expense?</p>
            <div className="mt-4 flex justify-end">
              <button onClick={handleDelete} className="bg-red-500 text-white p-2 rounded-md mr-2">Delete</button>
              <button onClick={() => setShowDeleteModal(false)} className="bg-gray-300 text-black p-2 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyExpenses;

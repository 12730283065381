import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";

Modal.setAppElement('#root');

const TenantDetails = () => {
  const [updatedTenant, setUpdatedTenant] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchTenantDetails = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          navigate('/login');
          return;
        }

        if (!id) {
          setError('Tenant ID is required');
          setIsLoading(false);
          return;
        }

        const response = await axiosInstance.get(`/management/tenants/${id}/tenantdetails`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setUpdatedTenant(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch tenant details:', error);
        setError('Failed to fetch tenant details');
        setIsLoading(false);
      }
    };

    fetchTenantDetails();
  }, [id, navigate]);

  const handleUpdateTenant = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const response = await axiosInstance.put(`/management/tenants/${id}/tenantdetails`, updatedTenant, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      setUpdatedTenant(response.data);
      setError(null);
      navigate(-1);
    } catch (error) {
      console.error('Error updating tenant:', error);
      setError('Failed to update tenant');
    }
  };

  const handleDeleteTenant = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      await axiosInstance.delete(`/management/tenants/${id}/tenantdetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      navigate(-1);
    } catch (error) {
      console.error('Error deleting tenant:', error);
      setError('Failed to delete tenant');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedTenant((prevTenant) => ({ ...prevTenant, [name]: value }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    handleDeleteTenant();
    closeModal();
  };

  const handleCall = () => {
    window.location.href = `tel:${updatedTenant.phone}`;
  };

  const handleMessage = () => {
    window.location.href = `sms:${updatedTenant.phone}`;
  };

  const handleWhatsApp = () => {
    window.open(`https://wa.me/${updatedTenant.phone}`, '_blank');
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500 font-bold">{error}</div>;
  }

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Tenant {updatedTenant.tenant_name || ''} Details</h2>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="tenant_name">
          Name:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="text"
          name="tenant_name"
          id="tenant_name"
          value={updatedTenant.tenant_name || ''}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="national_id_number">
          National ID:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="text"
          name="national_id_number"
          id="national_id_number"
          value={updatedTenant.national_id_number || ''}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="phone">
          Tenant's Phone:
        </label>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center">
          <input
            className="w-full px-3 py-2 border border-gray-300 rounded-t sm:rounded-l sm:rounded-t-none mb-2 sm:mb-0"
            type="text"
            name="phone"
            id="phone"
            value={updatedTenant.phone || ''}
            onChange={handleChange}
          />
          {updatedTenant.phone && (
            <div className="flex justify-between sm:justify-start">
              <button
                className="flex-1 sm:flex-initial bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded sm:rounded-none"
                onClick={handleCall}
              >
                <FaPhone className="inline mr-1" /> Call
              </button>
              <button
                className="flex-1 sm:flex-initial bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded sm:rounded-none mx-1 sm:mx-0"
                onClick={handleMessage}
              >
                <TiMessages className="inline mr-1" /> SMS
              </button>
              <button
                className="flex-1 sm:flex-initial bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded sm:rounded-r"
                onClick={handleWhatsApp}
              >
                <FaWhatsapp className="inline mr-1" /> WhatsApp
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="emergency_contact_name">
          Emergency Contact Name:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="text"
          name="emergency_contact_name"
          id="emergency_contact_name"
          value={updatedTenant.emergency_contact_name || ''}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="emergency_contact_phone">
          Emergency Contact Phone:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="text"
          name="emergency_contact_phone"
          id="emergency_contact_phone"
          value={updatedTenant.emergency_contact_phone || ''}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="emergency_contact_relationship">
          Emergency Contact Relationship:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="text"
          name="emergency_contact_relationship"
          id="emergency_contact_relationship"
          value={updatedTenant.emergency_contact_relationship || ''}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="date_tenancy_starts">
          Date Tenancy Starts:
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded"
          type="date"
          name="date_tenancy_starts"
          id="date_tenancy_starts"
          value={updatedTenant.date_tenancy_starts || ''}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-end">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
          onClick={handleUpdateTenant}
        >
          Update
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={openModal}
        >
          Delete
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Deletion"
        className="bg-white rounded-lg p-8 max-w-md mx-auto shadow-lg"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-xl font-bold mb-4">Are you sure you want to delete this tenant?</h2>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={confirmDelete}
          >
            Delete
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TenantDetails;

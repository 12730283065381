import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import Spinner from "../Spinner";
import RentCalculator from "../RentCalculator";

const RentalUnits = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCalculator, setShowCalculator] = useState(false);

  const fetchPropertyDetails = useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        navigate("/login");
        return;
      }

      const response = await axiosInstance.get(`/landlord/property/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Fetched property details:", response.data);
      setProperty(response.data);
      setError(null);
    } catch (error) {
      console.error("Failed to fetch property details:", error);
      if (error.response?.status === 401) {
        navigate("/login");
      } else {
        setError(
          error.response?.data?.detail ||
            "An error occurred while fetching property details"
        );
      }
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchPropertyDetails();
  }, [fetchPropertyDetails, location.key]);

  const handleCollectRent = (tenantId, tenantName, unitIdentity) => {
    navigate(`/rentcollection/${tenantId}/tenant`, {
      state: { tenantName, unitIdentity },
    });
  };

  const handleViewPaymentHistory = (tenantId) => {
    navigate(`/payment-history/${tenantId}`);
  };

  const handleRentOut = (unitId) => {
    navigate(`/addtenant/${unitId}/rentalunit`);
  };

  const handleRentReminder = (tenant, unitIdentity) => {
    if (tenant && parseFloat(tenant.balance) > 0) {
      if (tenant.phone) {
        const message = `Hello ${tenant.tenant_name}, your rent balance of Ksh:${tenant.balance} for house ( ${unitIdentity}) is due.Please pay promptly to avoid any issues. Thank you.
Best regards,
${property.name} Management`;
        const encodedMessage = encodeURIComponent(message);
        const smsLink = `sms:${tenant.phone}?body=${encodedMessage}`;
        window.location.href = smsLink;
      } else {
        console.error("Phone number not found for tenant:", tenant);
        alert("Unable to send reminder. Tenant phone number is missing.");
      }
    }
  };

  const filterRentalUnits = useCallback((units, query) => {
    if (!query) return units;
    const lowercaseQuery = query.toLowerCase();
    return units.filter((unit) => {
      const tenantName = unit.tenant
        ? unit.tenant.tenant_name.toLowerCase()
        : "";
      const unitIdentity = unit.unit_identity.toLowerCase();
      return (
        tenantName.includes(lowercaseQuery) ||
        unitIdentity.includes(lowercaseQuery)
      );
    });
  }, []);

  const getBalanceColorClass = (balance) => {
    if (balance === 0) return "text-black";
    return balance < 0 ? "text-green-600" : "text-red-600";
  };

  if (loading) return <Spinner />;
  if (error) return <p className="text-red-500">{error}</p>;

  const occupiedUnits =
    property?.rental_units.filter((unit) => unit.tenant) || [];
  const vacantUnits =
    property?.rental_units.filter((unit) => !unit.tenant) || [];
  const filteredRentalUnits = filterRentalUnits(
    property?.rental_units || [],
    searchQuery
  );

  return (
    <div className="p-4 md:p-8 lg:p-12">
      <div className="mb-4">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center text-green-700 mb-4">
          {property.name}
        </h1>
        <div className="flex flex-col sm:flex-row justify-center items-stretch">
          <p className="w-full sm:w-1/3 mx-2 mb-2 sm:mb-0 text-center text-lg md:text-xl bg-blue-100 text-blue-800 font-bold rounded-lg px-3 py-2 shadow-md">
            Total units: {property.total_units}
          </p>
          <p className="w-full sm:w-1/3 mx-2 mb-2 sm:mb-0 text-center text-lg md:text-xl bg-amber-100 text-amber-700 font-bold rounded-lg px-3 py-2 shadow-md">
            Vacant Units: {vacantUnits.length}
          </p>
          <p className="w-full sm:w-1/3 mx-2 text-center text-lg md:text-xl bg-indigo-100 text-indigo-800 font-bold rounded-lg px-3 py-2 shadow-md">
            Occupied Units: {occupiedUnits.length}
          </p>
        </div>
      </div>

      <button
        onClick={() => setShowCalculator(!showCalculator)}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
      >
        {showCalculator ? "Hide Rent Calculator" : "Show Rent Calculator"}
      </button>

      {showCalculator && <RentCalculator />}

      <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold text-green-700 mt-6 mb-4">
        Rental Units
      </h2>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search rental units..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className=" border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 shadow-sm"
        />
      </div>

      <div className="overflow-x-auto bg-white  shadow-lg">
        <table className="w-full">
          <thead>
            <tr className="bg-green-600 text-white">
              <th className="py-3 px-4 text-left">Unit Identity</th>
              <th className="py-3 px-4 text-left">Occupant</th>
              <th className="py-3 px-4 text-left">Rent Balance</th>
              <th className="py-3 px-4 text-left">Rent Rate</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRentalUnits.map((unit, index) => (
              <tr
                key={unit.id}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="py-3 px-4">
                  <Link
                    to={`/unitDetails/${unit.id}/unit`}
                    className="text-blue-600 hover:underline"
                  >
                    {unit.unit_identity}
                  </Link>
                </td>
                <td className="py-3 px-4">
                  {unit.tenant ? (
                    <Link
                      to={`/tenantdetails/${unit.tenant.id}/tenant`}
                      className="text-blue-600 hover:underline"
                    >
                      {unit.tenant.tenant_name}
                    </Link>
                  ) : (
                    <span className="text-red-600">Vacant</span>
                  )}
                </td>
                <td
                  className={`py-3 px-4 ${getBalanceColorClass(
                    unit.tenant ? parseFloat(unit.tenant.balance) : 0
                  )}`}
                >
                  Ksh:{unit.tenant ? unit.tenant.balance : "0.00"}
                </td>
                <td className="py-3 px-4">
                  Ksh:{unit.current_monthly_rent_rate.rent_rate}
                </td>
                <td className="py-3 px-4">
                  {unit.tenant ? (
                    <div className="flex flex-wrap gap-2">
                      <button
                        className="bg-yellow-700 text-white py-1 px-2 rounded hover:bg-yellow-600 transition duration-300 text-sm"
                        onClick={() =>
                          handleCollectRent(
                            unit.tenant.id,
                            unit.tenant.tenant_name,
                            unit.unit_identity
                          )
                        }
                      >
                        Collect Rent
                      </button>
                      <button
                        className="bg-green-800 text-white py-1 px-2 rounded hover:bg-green-600 transition duration-300 text-sm"
                        onClick={() => handleViewPaymentHistory(unit.tenant.id)}
                      >
                        Payment History
                      </button>
                      {parseFloat(unit.tenant.balance) > 0 && (
                        <button
                          className="bg-blue-600 text-white py-1 px-2 rounded hover:bg-blue-500 transition duration-300 text-sm"
                          onClick={() =>
                            handleRentReminder(unit.tenant, unit.unit_identity)
                          }
                        >
                          Rent Reminder
                        </button>
                      )}
                    </div>
                  ) : (
                    <button
                      className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-300 text-sm"
                      onClick={() => handleRentOut(unit.id)}
                    >
                      Add Tenant
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Link
        to={`/add-rental-unit/${id}`}
        className="inline-block mt-6 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
      >
        Add Rental Unit
      </Link>
    </div>
  );
};

export default RentalUnits;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const EditProperty = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    total_units: 0,
    amenities: [],
  });
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const amenitiesResponse = await axiosInstance.get('/landlord/amenities/');
        setAmenities(amenitiesResponse.data);

        const propertyResponse = await axiosInstance.get(`/landlord/property/${id}`);
        setProperty(propertyResponse.data);
        setFormData({
          name: propertyResponse.data.name,
          location: propertyResponse.data.location,
          amenities: propertyResponse.data.amenities.map(amenity => amenity.id.toString()),
        });

        setLoading(false);
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          navigate('/login');
        } else {
          setError(error.response?.data?.detail || 'An error occurred while fetching the property');
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const newAmenities = checked
      ? [...formData.amenities, value]
      : formData.amenities.filter(amenityId => amenityId !== value);
    setFormData({ ...formData, amenities: newAmenities });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      amenities: formData.amenities.map(id => parseInt(id, 10)),
    };

    try {
      await axiosInstance.put(`/landlord/property/${id}/`, data);
      navigate('/landlord/home', { state: { message: `Property ${property.name} has been updated.` } });
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while updating the property');
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/landlord/property/${id}/`);
      navigate('/landlord/home', { state: { message: `Property ${property.name} has been deleted.` } });
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while deleting the property');
      }
    }
  };

  if (loading) return <div className="flex items-center justify-center h-screen font-light">Loading...</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-red-500 font-light">{error}</div>;

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg font-light">
      <h1 className="text-2xl font-bold mb-6">Edit {property.name}</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium">Location</label>
          <textarea
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 font-medium">Amenities</label>
          <div className="overflow-y-auto max-h-48">
            {amenities.map((amenity) => (
              <div key={amenity.id} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`amenity-${amenity.id}`}
                  value={amenity.id.toString()}
                  checked={formData.amenities.includes(amenity.id.toString())}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor={`amenity-${amenity.id}`}>{amenity.name}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Delete
          </button>
        </div>
      </form>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg font-light">
            <h2 className="text-xl font-bold mb-4">We're sorry to see you want to delete {property.name}!</h2>
            <p className="mb-4">
              Our software offers unparalleled convenience and flexibility, allowing you to manage your property from anywhere in the world. Are you sure you want to miss out on these benefits?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 mr-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProperty;

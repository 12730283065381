import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const UnitDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [unit, setUnit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [updatedRentRate, setUpdatedRentRate] = useState('');
  const [roomTypeChoices, setRoomTypeChoices] = useState({});

  useEffect(() => {
    const fetchUnitDetails = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          navigate('/login');
          return;
        }

        const [unitResponse, roomTypeChoicesResponse] = await Promise.all([
          axiosInstance.get(`/landlord/rental-units/${id}/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
          axiosInstance.get('/landlord/room-type-choices/', {
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
        ]);

        setUnit(unitResponse.data);
        setRoomTypeChoices(roomTypeChoicesResponse.data);
      } catch (error) {
        console.error('Failed to fetch unit details:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        } else {
          setError(error.response?.data?.detail || 'An error occurred while fetching unit details');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUnitDetails();
  }, [id, navigate]);

  const handleUpdate = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const updatedUnit = {
        property_with_rental_unit: unit.property_with_rental_unit.id,
        unit_identity: unit.unit_identity,
        current_monthly_rent_rate: unit.current_monthly_rent_rate?.id,
        unit_notes: unit.unit_notes,
        rent_rate: updatedRentRate || unit.current_monthly_rent_rate?.rent_rate,
        room_type: unit.room_type,
      };

      console.log('Sending update data:', updatedUnit); // For debugging

      const response = await axiosInstance.put(`/landlord/rental-units/${id}/`, updatedUnit, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setUnit(response.data);
      setUpdatedRentRate('');
      navigate(-1);
    } catch (error) {
      console.error('Failed to update unit details:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while updating unit details');
      }
    }
  };

  const handleDelete = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      await axiosInstance.delete(`/landlord/rental-units/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      navigate(-1);
    } catch (error) {
      console.error('Failed to delete unit:', error);
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while deleting unit');
      }
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto max-w-md mt-8 p-6 border border-gray-300 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Rental Unit Details</h2>
      <p className="mb-4"><strong>Property:</strong> {unit.property_with_rental_unit.name}</p>
      <input
        className="block w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
        type="text"
        value={unit.unit_identity}
        onChange={(e) => setUnit({ ...unit, unit_identity: e.target.value })}
        placeholder="Unit Identity"
      />
      <div className="mb-4">
        <label className="block mb-2 font-semibold">Current Monthly Rent Rate:</label>
        <input
          className="block w-full border border-gray-300 rounded-md px-3 py-2"
          type="number"
          value={updatedRentRate || unit.current_monthly_rent_rate?.rent_rate}
          onChange={(e) => setUpdatedRentRate(e.target.value)}
          placeholder="Current Monthly Rent Rate"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-semibold">Room Type:</label>
        <select
          className="block w-full border border-gray-300 rounded-md px-3 py-2"
          value={unit.room_type}
          onChange={(e) => setUnit({ ...unit, room_type: e.target.value })}
        >
          {Object.entries(roomTypeChoices).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <textarea
        className="block w-full border border-gray-300 rounded-md px-3 py-2 mb-4"
        value={unit.unit_notes || ''}
        onChange={(e) => setUnit({ ...unit, unit_notes: e.target.value })}
        placeholder="Unit Notes"
        rows={4}
      />
      <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md mr-2" onClick={handleUpdate}>
        Update
      </button>
      <button className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md" onClick={() => setShowDeleteConfirmation(true)}>
        Delete
      </button>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this rental unit?</p>
            <div className="flex justify-end">
              <button className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md mr-2" onClick={handleDelete}>
                Delete
              </button>
              <button className="bg-gray-500 hover:bg-gray-600 text-white font-semibold px-4 py-2 rounded-md" onClick={() => setShowDeleteConfirmation(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitDetails;

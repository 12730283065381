import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const RentCollection = () => {
  const { id } = useParams(); // Tenant ID
  const navigate = useNavigate();
  const location = useLocation();
  const { tenantName, unitIdentity } = location.state || {};

  const [formData, setFormData] = useState({
    amount_paid: '',
    mode_of_payment: '',
    date_paid: new Date().toISOString().split('T')[0],
    payment_details: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentModes, setPaymentModes] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchPaymentModes = async () => {
      try {
        const response = await axiosInstance.get('/management/payment-modes/');
        const paymentModesData = response.data;
        const paymentModesArray = Object.entries(paymentModesData).map(([id, name]) => ({
          id,
          name,
        }));
        setPaymentModes(paymentModesArray);
      } catch (error) {
        console.error('Failed to fetch payment modes:', error);
        setError('Failed to fetch payment modes. Please try again later.');
      }
    };

    fetchPaymentModes();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = () => {
    return formData.amount_paid && formData.mode_of_payment && formData.date_paid;
  };

  const handlePayment = async () => {
    if (!isFormValid()) {
      setError('All fields must be filled');
      return;
    }

    try {
      setIsLoading(true);
      setError('');

      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        navigate('/login');
        return;
      }

      const response = await axiosInstance.post(
        `/management/tenants/${id}/rent-payments/`,
        {
          ...formData,
          amount_paid: parseFloat(formData.amount_paid),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log('Payment successful:', response.data);
      setIsLoading(false);
      setShowModal(true); // Show success modal
    } catch (error) {
      console.error('Failed to make payment:', error);
      setError(error.response?.data?.error || 'An error occurred while making payment');
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate(-1);
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
      <h1 className="text-2xl font-bold mb-6 text-center">
        Collect Rent for {tenantName} occupying {unitIdentity}
      </h1>
      <h3 class="text-xl font-bold text-red-600 border-l-4 border-red-600 pl-2 mt-4 mb-4">
  Note:Please ensure that only received payments are recorded.
</h3>


      <form>
        <div className="mb-4">
          <label htmlFor="amount_paid" className="block text-sm font-medium text-gray-700">
            Amount Paid
          </label>
          <input
            type="number"
            id="amount_paid"
            name="amount_paid"
            value={formData.amount_paid}
            onChange={handleInputChange}
            placeholder="Enter amount"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="mode_of_payment" className="block text-sm font-medium text-gray-700">
            Payment Mode
          </label>
          <select
            id="mode_of_payment"
            name="mode_of_payment"
            value={formData.mode_of_payment}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="" disabled>
              Select Payment Mode
            </option>
            {paymentModes.map((mode) => (
              <option key={mode.id} value={mode.id}>
                {mode.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="date_paid" className="block text-sm font-medium text-gray-700">
            Date Paid
          </label>
          <input
            type="date"
            id="date_paid"
            name="date_paid"
            value={formData.date_paid}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="payment_details" className="block text-sm font-medium text-gray-700">
            Payment Details
          </label>
          <textarea
            id="payment_details"
            name="payment_details"
            value={formData.payment_details}
            onChange={handleInputChange}
            placeholder="Enter payment details (e.g., Mpesa message, bank deposit message)"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button
          type="button"
          onClick={handlePayment}
          disabled={isLoading || !isFormValid()}
          className={`w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading || !isFormValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? 'Processing...' : 'Pay'}
        </button>
        {error && <p className="mt-4 text-red-500 text-sm">Error: {error}</p>}
      </form>

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Payment Successful
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      You have successfully recorded the rent payment for {tenantName} occupying {unitIdentity}.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RentCollection;
